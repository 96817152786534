import { Icon, IconProps } from './Icon';
import { Edit } from '@styled-icons/boxicons-solid';

export function EditIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Edit />
    </Icon>
  );
}
